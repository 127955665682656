import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ISO6391 from "iso-639-1";
import PhoneNumberInput from "../../../components/form/PhoneNumberInput";
import ConfirmTourBookingModal from "../../../components/modal/ConfirmTourBookingModal.jsx";
import { useDispatch, useSelector } from "react-redux";
import { useTours } from "../../../hooks/useTours.js";
import Loader from "../../../utils/loader.js";
import { formatDate } from "../../../utils/date.js";
import { URLdecode, URLencode } from "../../../utils/url.js";
import { setConfirmTourBookingModal } from "../../../redux/reducers/modalSlice.js";
import { useSnackbar } from "notistack";
import useDateFormat from "../../../hooks/useDateFormat.js";
import { formatMoney } from "../../../features/utils/formatMoney.js";
import BookingQuestionMeeetingPoint from "../../../components/tour/BookingQuestionMeeetingPoint.jsx";
import BookingQuestionMeetingAndPickup from "../../../components/tour/BookingQuestionMeetingAndPickup.jsx";

function TourOrderUserInfoPage() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    getTourActivity,
    getTourAvailability,
    questionsSetup,
    groupQuestionsConditioning,
    handleAnswerChange,
  } = useTours();
  const { capitalizeFirstLetter } = useDateFormat();
  const { tour, tourAvailability, tourGroupQuestions, tourTouristQuestions } =
    useSelector((state) => state.tourData);

  const params = URLdecode();
  const date = params?.date;
  const productCode = params?.productCode;
  const productOptionCode = params?.productOptionCode;
  const adult = params?.adult;
  const child = params?.child;
  const infant = params?.infant;
  const languageGuides = params?.languageGuides
    ? JSON.parse(params?.languageGuides)
    : null;

  const [email, setEmail] = useState(params?.email || "");
  const [phone, setPhone] = useState(params?.phone || "");
  const [payOption, setPayOption] = useState("now");
  const [tourLoading, setTourLoading] = useState(false);
  const [availabilityLoading, setAvailabilityLoading] = useState(false);
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [language, setLanguage] = useState("");

  const arrivalModeQuestion = tourGroupQuestions.find(
    (q) => q.id === "TRANSFER_ARRIVAL_MODE"
  );
  const departureModeQuestion = tourGroupQuestions.find(
    (q) => q.id === "TRANSFER_DEPARTURE_MODE"
  );

  const loadData = async () => {
    if (productCode !== tour?.productCode) {
      await getTourActivity(setTourLoading);
    }
    if (productCode !== tourAvailability?.productCode) {
      await getTourAvailability(setAvailabilityLoading);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    questionsSetup();
  }, [tour]);

  // useEffect(() => {
  //   groupQuestionsConditioning(groupQuestions, setGroupQuestions);
  // }, [travelArrivalMode, travelDepartureMode]);
  useEffect(() => {
    groupQuestionsConditioning();
  }, [tour, arrivalModeQuestion?.answer, departureModeQuestion?.answer]);

  const handlePayOptionChoose = (text) => {
    setPayOption(text);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const coverImage = () => {
    let cover = "";
    if (tour) {
      if (tour?.images) {
        tour?.images?.map((image, index) => {
          if (image?.isCover === true) {
            cover = image?.variants[12]?.url;
          }
        });
        if (!cover) {
          cover = tour?.images[0]?.variants[12]?.url;
        }
      }
    }
    return cover;
  };

  const getPrices = () => {
    let data = [];
    if (!tourAvailability) return data;
    const chosenPackage = tourAvailability?.bookableItems?.find(
      (item) => item.productOptionCode === productOptionCode
    );
    if (chosenPackage) {
      let lineItems = chosenPackage.lineItems;
      if (lineItems && lineItems?.length > 0) {
        lineItems.map((item, index) => {
          let label = `${capitalizeFirstLetter(item?.ageBand)} (${
            item?.numberOfTravelers
          })`;
          let price = item?.subtotalPrice?.price?.partnerNetPrice || 0;
          price = formatMoney(price);
          data.push({
            label,
            price,
          });
        });
      }
    }
    return data;
  };

  const getTotalPrice = () => {
    if (!tourAvailability) return "";
    const chosenPakcage = tourAvailability?.bookableItems?.find(
      (item) => item.productOptionCode === productOptionCode
    );
    const priceItem = chosenPakcage?.totalPrice?.farePrice;

    if (!priceItem) return "";

    return `${(priceItem?.total).toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
    })}`;
  };

  const inputsFullfilled = () => {
    let valid = true;
    if (languageGuides && languageGuides?.length > 0 && !language) {
      valid = false;
      enqueueSnackbar(`Language guide is required`, { variant: "warning" });
    }
    if (!email) {
      valid = false;
      enqueueSnackbar(`Contact email is required!`, { variant: "warning" });
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      enqueueSnackbar(`Invalid contact email!`, { variant: "warning" });
    }
    if (!phone) {
      valid = false;
      enqueueSnackbar(`Phone is required!`, { variant: "warning" });
    }
    if (tourTouristQuestions)
      for (let i = 0; i < tourTouristQuestions.length; i++) {
        for (let j = 0; j < tourTouristQuestions[i]?.questions.length; j++) {
          let person = tourTouristQuestions[i];
          let question = person?.questions[j];
          if (question?.required === "MANDATORY") {
            if (!question?.answer) {
              valid = false;
              enqueueSnackbar(
                `${question?.label} for ${person.label} is required!`,
                { variant: "warning" }
              );
            }
          }
          if (
            question?.units &&
            question?.units?.length > 0 &&
            !question?.unit &&
            question?.answer
          ) {
            valid = false;
            enqueueSnackbar(
              `Unit is required for ${question?.label} in ${person.label}!`,
              { variant: "warning" }
            );
          }
        }
      }
    if (tourGroupQuestions) {
      for (let i = 0; i < tourGroupQuestions.length; i++) {
        let question = tourGroupQuestions[i];
        if (
          question?.required === "MANDATORY" ||
          question?.required === "CONDITIONAL"
        ) {
          if (!question?.answer) {
            valid = false;
            enqueueSnackbar(
              `${question?.label} in group details is required!`,
              { variant: "warning" }
            );
          }
        }
        if (
          question?.units &&
          question?.units?.length > 0 &&
          !question?.unit &&
          question?.answer
        ) {
          valid = false;
          enqueueSnackbar(`Unit is required for ${question?.label} i!`, {
            variant: "warning",
          });
        }
      }
    }

    return valid;
  };

  const handleBook = () => {
    const valid = inputsFullfilled();
    if (!valid) return;
    let parameters = {
      ...params,
      people: JSON.stringify(tourTouristQuestions),
      group: JSON.stringify(tourGroupQuestions),
      payOption: payOption,
      email,
      phone,
    };
    if (languageGuides && languageGuides?.length > 0) {
      let selectedLanguage = languageGuides?.find(
        (ln) => ln?.language === language
      );
      parameters = {
        ...parameters,
        languageGuide: JSON.stringify(selectedLanguage),
      };
    }
    navigate(`/tour/userinfo?${URLencode(parameters)}`);
    dispatch(setConfirmTourBookingModal(true));
  };

  return (
    <div className={Classname.container}>
      <div className={Classname.content}>
        {/* Page title */}
        <div className={Classname.titleContainer}>
          {`Tour > Orders > New Order >`}{" "}
          <span className={Classname.titleMain}>Booking details</span>
        </div>
        <ConfirmTourBookingModal />
        {/* Detail container */}
        <div className={Classname.detailContainer}>
          {/* Detail */}
          <div className={Classname.detailContent}>
            <div className={Classname.detailPrivacyContainer}>
              <img
                src="/IconLock.svg"
                alt=""
                className={Classname.detailPrivacyIcon}
              />
              <span>
                We take privacy issues seriously. You can be sure that your
                personal data is securely protected.
              </span>
            </div>

            {languageGuides && languageGuides?.length > 0 ? (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Language guide
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  label="Language guide"
                  onChange={(e) => setLanguage(e.target.value)}
                >
                  {languageGuides?.map((lan, index) => (
                    <MenuItem value={lan?.language} key={index}>
                      {ISO6391.getName(lan?.language) || "Unkown language"} -{" "}
                      {lan?.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <></>
            )}

            <div className={Classname.detailTitleContainer}>
              <span className={Classname.detailTitle}>Contact details</span>
            </div>

            <div className={Classname.detailInputsContainer}>
              <div className={Classname.detailInputContainer}>
                <span className={Classname.detailInputLabel}>
                  Enter order email*
                </span>
                <div className={Classname.detailInput}>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={Classname.detailInputBar}
                    placeholder="e.g xyz@gmail.com"
                  />
                </div>
              </div>
              <div className={Classname.detailInputContainer}>
                <span className={Classname.detailInputLabel}>
                  Enter order phone number*
                </span>
                <PhoneNumberInput
                  value={phone}
                  onChange={(value) => setPhone(value)}
                  className={Classname.detailPhoneInput}
                />
              </div>
            </div>

            <div className={Classname.detailInfoContainer}>
              <img
                src="/IconInfo.svg"
                alt=""
                className={Classname.detailInfoIcon}
              />
              <span>
                Use all given names and surnames exactly as they appear in your
                passport/ID to avoid boarding complications
              </span>
            </div>

            <Loader loading={tourLoading} />
            {!tourLoading && (
              <>
                {tourTouristQuestions?.map((person, index) => (
                  <div className="flex flex-col w-full" key={index}>
                    <div className={Classname.detailTitleContainer}>
                      <span className={Classname.detailTitle}>
                        {capitalizeFirstLetter(person?.label)}{" "}
                        {index === 0 ? (
                          <span className="font-normal text-sm">
                            (Lead traveler)
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </div>

                    {/* Questions */}

                    <div className={Classname.detailInputsContainer}>
                      {person?.questions?.map((question, idx) =>
                        question?.id === "AGEBAND" ? (
                          <></>
                        ) : question?.id === "PICKUP_POINT" ? (
                          <BookingQuestionMeeetingPoint
                            question={question}
                            arrivalQuestion={arrivalModeQuestion}
                            onChange={(e) =>
                              handleAnswerChange({
                                value: e,
                                field: question.id,
                                travellerIndex: index,
                                questionIndex: idx,
                              })
                            }
                            onUnitChange={(e) =>
                              handleAnswerChange({
                                value: e,
                                field: question.id,
                                travellerIndex: index,
                                questionIndex: idx,
                                unit: true,
                              })
                            }
                          />
                        ) : question.allowedAnswers ? (
                          <div
                            className={Classname.detailInputContainer}
                            key={idx}
                          >
                            <span className={Classname.detailInputLabel}>
                              {question?.label}
                              {question?.required === "MANDATORY" ? "*" : ""}
                            </span>
                            <FormControl fullWidth>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                className="h-12"
                                value={question?.answer}
                                onChange={(e) =>
                                  handleAnswerChange({
                                    value: e.target.value,
                                    field: question.id,
                                    travellerIndex: index,
                                    questionIndex: idx,
                                  })
                                }
                              >
                                {question.allowedAnswers?.map(
                                  (answer, index1) => (
                                    <MenuItem value={answer} key={index1}>
                                      {capitalizeFirstLetter(answer)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        ) : (
                          <div
                            key={idx}
                            className={
                              question?.units
                                ? Classname.detailInputContainer1
                                : Classname.detailInputContainer
                            }
                          >
                            <div className={Classname.detailInputContainer}>
                              <span className={Classname.detailInputLabel}>
                                {question?.id === "WEIGHT"
                                  ? "Weight"
                                  : question?.label}
                                {question?.required === "MANDATORY" ? "*" : ""}
                              </span>
                              <div className={Classname.detailInput}>
                                <input
                                  type="text"
                                  className={Classname.detailInputBar}
                                  value={question?.answer}
                                  maxLength={question?.maxLength}
                                  onChange={(e) =>
                                    handleAnswerChange({
                                      value: e.target.value,
                                      field: question.id,
                                      travellerIndex: index,
                                      questionIndex: idx,
                                    })
                                  }
                                />
                              </div>
                            </div>

                            {question?.units && (
                              <div className={"w-44 mt-2"}>
                                <FormControl fullWidth>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    className="h-12"
                                    value={question?.unit}
                                    onChange={(e) =>
                                      handleAnswerChange({
                                        value: e.target.value,
                                        field: question.id,
                                        travellerIndex: index,
                                        questionIndex: idx,
                                        unit: true,
                                      })
                                    }
                                  >
                                    {question?.units?.map((unit, index1) => (
                                      <MenuItem value={unit} key={index1}>
                                        {capitalizeFirstLetter(unit)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </div>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
            <div className="mt-10"></div>
            {/* Group details */}

            <GroupQuestions />
            <DepartureQuestions />
            <div className={Classname.detailTitleContainer1}>
              <span className={Classname.detailTitle}>
                Paying now, or later?
              </span>
            </div>

            <div className={Classname.detailPayOptionsContainer}>
              <div
                className={`${Classname.detailPayOption} ${
                  payOption === "now"
                    ? Classname.detailPayOptionActive
                    : Classname.detailPayOptionInActive
                }`}
                onClick={() => handlePayOptionChoose("now")}
              >
                <div className={Classname.detailPayOptionIconContainer}>
                  {payOption === "now" ? (
                    <img
                      src="/IconCheckmark3.svg"
                      alt=""
                      className={Classname.detailPayOptionIcon}
                    />
                  ) : (
                    <div
                      className={Classname.detailPayOptionIconInActive}
                    ></div>
                  )}
                </div>
                <div className={Classname.detailPayOptionDetail}>
                  <span className={Classname.detailPayOptionTitle}>
                    Pay now
                  </span>
                  <p className={Classname.detailPayOptionDescription}>
                    Pay now and confirm seat and baggage selection.
                  </p>
                </div>
              </div>
              <div
                className={`${Classname.detailPayOption} ${
                  payOption === "later"
                    ? Classname.detailPayOptionActive
                    : Classname.detailPayOptionInActive
                }`}
                onClick={() => handlePayOptionChoose("later")}
              >
                <div className={Classname.detailPayOptionIconContainer}>
                  {payOption === "later" ? (
                    <img
                      src="/IconCheckmark3.svg"
                      alt=""
                      className={Classname.detailPayOptionIcon}
                    />
                  ) : (
                    <div
                      className={Classname.detailPayOptionIconInActive}
                    ></div>
                  )}
                </div>
                <div className={Classname.detailPayOptionDetail}>
                  <span className={Classname.detailPayOptionTitle}>
                    Book on hold
                  </span>
                  <p className={Classname.detailPayOptionDescription}>
                    Hold price and pay at a later date.
                  </p>
                </div>
              </div>
            </div>
            <div className={Classname.detailButtonsContainer}>
              <button
                className={Classname.detailBackButton}
                onClick={handleBack}
              >
                Back
              </button>
              <button
                className={Classname.detailContinueButton}
                onClick={handleBook}
              >
                Continue
              </button>
            </div>
          </div>

          {/* CTA container */}
          <div className={Classname.detailCTAContainer}>
            <Loader loading={tourLoading} />
            <div className={Classname.detailCTANameContainer}>
              {coverImage() && (
                <img
                  src={coverImage()}
                  alt=""
                  className={Classname.detailCTAImage}
                />
              )}
              <span className={Classname.mainDetailName}>{tour?.title}</span>
            </div>
            <div className={Classname.mainDetailPoint}>
              <img
                src="/tourIconLocation.svg"
                alt=""
                className={Classname.mainDetailIcon}
              />
              <span>Berlin City Boat Tour Starting at Friedrichstraße</span>
            </div>
            {adult > 0 && (
              <div className={Classname.mainDetailPoint}>
                <img
                  src="/tourIconPerson.svg"
                  alt=""
                  className={Classname.mainDetailIcon}
                />
                <span>{adult} Adult (Age 14 - 99)</span>
              </div>
            )}
            <div className={Classname.mainDetailPoint}>
              <img
                src="/tourIconDate.svg"
                alt=""
                className={Classname.mainDetailIcon}
              />
              <span>{formatDate(date)}</span>
            </div>
            {params?.startTime && (
              <div className={Classname.mainDetailPoint}>
                <img
                  src="/tourIconTime.svg"
                  alt=""
                  className={Classname.mainDetailIcon}
                />
                <span>Starting Time: {params?.startTime}</span>
              </div>
            )}

            <div className={Classname.detailCTAPriceTitleContainer}>
              <span className={Classname.detailCTAPriceTitle}>
                Price Summary
              </span>
            </div>
            <Loader loading={availabilityLoading} />
            {getPrices()?.map((detail, index) => (
              <div className={Classname.detailCTADetailContainer} key={index}>
                <span>{detail?.label}:</span>
                <span>{detail?.price}</span>
              </div>
            ))}

            <div className={Classname.detailCTATotalContainer}>
              <span>Tour total:</span>
              <span>{getTotalPrice()}</span>
            </div>

            {tour?.cancellationPolicy && (
              <div className={Classname.detailCTABenefit}>
                <span className={Classname.detailCTABenefitTitle}>
                  Cancellation policy
                </span>
                <div className={Classname.detailCTABenefitDescription}>
                  <img
                    src="/IconCheckmark1.svg"
                    alt=""
                    className={Classname.detailCTABenefitIcon}
                  />
                  <span>{tour?.cancellationPolicy?.description}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const GroupQuestions = () => {
  const { capitalizeFirstLetter } = useDateFormat();
  const { handleAnswerChange } = useTours();
  const { tourGroupQuestions, tour } = useSelector((state) => state.tourData);
  const [locations, setLocations] = useState(tour?.locations || []);
  const departureModeQuestion = tourGroupQuestions.find(
    (q) => q.id === "TRANSFER_DEPARTURE_MODE"
  );
  let pickupType = departureModeQuestion?.answer;
  let freeTextAllowed =
    tour?.logistics?.travelerPickup?.allowCustomTravelerPickup;

  const parseLocations = () => {
    let travellerPickupLocations =
      tour?.logistics?.travelerPickup?.locations || [];
    let baseLocations = tour?.locations || [];
    if (baseLocations) {
      baseLocations = [...baseLocations].sort((a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        return nameA > nameB ? 1 : -1;
      });

      if (travellerPickupLocations) {
        baseLocations = baseLocations?.map((location) => {
          let travellerPickup = travellerPickupLocations?.find(
            (pickup) => pickup?.location?.ref === location?.reference
          );
          let updatedLocation = { ...location };
          if (travellerPickup) {
            updatedLocation = {
              ...updatedLocation,
              pickupType: travellerPickup?.pickupType,
            };
          }
          return updatedLocation;
        });
      }

      pickupType =
        departureModeQuestion?.answer === "AIR"
          ? "AIRPORT"
          : departureModeQuestion?.answer === "SEA"
          ? "PORT"
          : "HOTEL";
      let contactSupplier = baseLocations?.find(
        (loc) => loc?.reference === "CONTACT_SUPPLIER_LATER"
      );
      let pureLocations = baseLocations?.filter(
        (loc) =>
          loc?.reference !== "CONTACT_SUPPLIER_LATER" &&
          loc?.reference !== "MEET_AT_DEPARTURE_POINT"
      );
      if (pickupType) {
        let pickupTypeAvailable = baseLocations?.find((loc) => loc?.pickupType);
        if (pickupTypeAvailable) {
          if (pickupType === "AIRPORT" || pickupType === "PORT") {
            pureLocations = pureLocations.filter(
              (loc) => loc?.pickupType === pickupType
            );
          } else {
            pureLocations = pureLocations.filter(
              (loc) =>
                loc?.pickupType === "HOTEL" ||
                loc?.pickupType === "LOCATION" ||
                loc?.pickupType === "OTHER"
            );
          }
        }
      }
      setLocations(pureLocations);
    }
  };

  useEffect(() => {
    parseLocations();
  }, [tour, departureModeQuestion?.answer]);

  return (
    <div className={Classname.detailInputsContainer}>
      {tourGroupQuestions?.map((question, index) =>
        question?.id === "PICKUP_POINT" ? (
          <BookingQuestionMeetingAndPickup
            key={index}
            index={index}
            question={question}
          />
        ) : (
          <></>
        )
      )}
      {tourGroupQuestions?.map((question, index) =>
        question?.departure || question?.pickup ? (
          <></>
        ) : question.allowedAnswers ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {question.allowedAnswers?.map((answer, idx) => (
                  <MenuItem value={answer} key={idx}>
                    {capitalizeFirstLetter(answer)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : question?.unit === "LOCATION_REFERENCE" ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {locations?.map((answer, index1) => (
                  <MenuItem value={answer?.reference} key={index1}>
                    <div className="flex gap-2 items-center">
                      <img
                        src="/IconLocationGreen.svg"
                        alt=""
                        className={"w-5 object-contain"}
                      />
                      <span>{answer?.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div
            key={index}
            className={
              question?.units
                ? Classname.detailInputContainer1
                : Classname.detailInputContainer
            }
          >
            <div className={Classname.detailInputContainer}>
              <span className={Classname.detailInputLabel}>
                {question?.label}
                {question?.required === "MANDATORY" ? "*" : ""}
              </span>
              <div className={Classname.detailInput}>
                <input
                  type="text"
                  className={Classname.detailInputBar}
                  value={question?.answer}
                  maxLength={question?.maxLength}
                  onChange={(e) =>
                    handleAnswerChange({
                      value: e.target.value,
                      field: question.id,
                      group: true,
                      questionIndex: index,
                    })
                  }
                />
              </div>
            </div>

            {question?.units &&
              (question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
                ? freeTextAllowed
                : true) && (
                <div className={"w-44 mt-2"}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="h-12"
                      value={question?.unit}
                      onChange={(e) =>
                        handleAnswerChange({
                          value: e.target.value,
                          field: question.id,
                          group: true,
                          questionIndex: index,
                          unit: true,
                        })
                      }
                    >
                      {question?.units?.map((unit, idx) => (
                        <MenuItem value={unit} key={idx}>
                          {capitalizeFirstLetter(unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
          </div>
        )
      )}
    </div>
  );
};

const DepartureQuestions = () => {
  const { capitalizeFirstLetter } = useDateFormat();
  const { handleAnswerChange } = useTours();
  const { tourGroupQuestions, tour } = useSelector((state) => state.tourData);
  const [locations, setLocations] = useState(tour?.locations || []);
  const departureModeQuestion = tourGroupQuestions.find(
    (q) => q.id === "TRANSFER_DEPARTURE_MODE"
  );
  let pickupType = departureModeQuestion?.answer;
  let freeTextAllowed =
    tour?.logistics?.travelerPickup?.allowCustomTravelerPickup;

  const parseLocations = () => {
    let travellerPickupLocations =
      tour?.logistics?.travelerPickup?.locations || [];
    let baseLocations = tour?.locations || [];
    if (baseLocations) {
      baseLocations = [...baseLocations].sort((a, b) => {
        let nameA = a.name?.toLowerCase();
        let nameB = b.name?.toLowerCase();
        return nameA > nameB ? 1 : -1;
      });

      if (travellerPickupLocations) {
        baseLocations = baseLocations?.map((location) => {
          let travellerPickup = travellerPickupLocations?.find(
            (pickup) => pickup?.location?.ref === location?.reference
          );
          let updatedLocation = { ...location };
          if (travellerPickup) {
            updatedLocation = {
              ...updatedLocation,
              pickupType: travellerPickup?.pickupType,
            };
          }
          return updatedLocation;
        });
      }

      pickupType =
        departureModeQuestion?.answer === "AIR"
          ? "AIRPORT"
          : departureModeQuestion?.answer === "SEA"
          ? "PORT"
          : "HOTEL";
      let contactSupplier = baseLocations?.find(
        (loc) => loc?.reference === "CONTACT_SUPPLIER_LATER"
      );
      let pureLocations = baseLocations?.filter(
        (loc) =>
          loc?.reference !== "CONTACT_SUPPLIER_LATER" &&
          loc?.reference !== "MEET_AT_DEPARTURE_POINT"
      );
      if (pickupType) {
        let pickupTypeAvailable = baseLocations?.find((loc) => loc?.pickupType);
        if (pickupTypeAvailable) {
          if (pickupType === "AIRPORT" || pickupType === "PORT") {
            pureLocations = pureLocations.filter(
              (loc) => loc?.pickupType === pickupType
            );
          } else {
            pureLocations = pureLocations.filter(
              (loc) =>
                loc?.pickupType === "HOTEL" ||
                loc?.pickupType === "LOCATION" ||
                loc?.pickupType === "OTHER"
            );
          }
        }
      }
      setLocations(pureLocations);
    }
  };

  useEffect(() => {
    parseLocations();
  }, [tour, departureModeQuestion?.answer]);

  if (tourGroupQuestions?.filter((q) => q?.departure)?.length === 0)
    return <></>;

  return (
    <div className={Classname.detailInputsContainer}>
      <div className="w-full mt-6">
        <span className={Classname.detailTitle}>Departure details</span>
      </div>
      {tourGroupQuestions?.map((question, index) =>
        !question?.departure ? (
          <></>
        ) : question.allowedAnswers ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {question.allowedAnswers?.map((answer, idx) => (
                  <MenuItem value={answer} key={idx}>
                    {capitalizeFirstLetter(answer)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : question?.unit === "LOCATION_REFERENCE" ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {locations?.map((answer, index1) => (
                  <MenuItem value={answer?.reference} key={index1}>
                    <div className="flex gap-2 items-center">
                      <img
                        src="/IconLocationGreen.svg"
                        alt=""
                        className={"w-5 object-contain"}
                      />
                      <span>{answer?.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div
            key={index}
            className={
              question?.units
                ? Classname.detailInputContainer1
                : Classname.detailInputContainer
            }
          >
            <div className={Classname.detailInputContainer}>
              <span className={Classname.detailInputLabel}>
                {question?.label}
                {question?.required === "MANDATORY" ? "*" : ""}
              </span>
              <div className={Classname.detailInput}>
                <input
                  type="text"
                  className={Classname.detailInputBar}
                  value={question?.answer}
                  maxLength={question?.maxLength}
                  onChange={(e) =>
                    handleAnswerChange({
                      value: e.target.value,
                      field: question.id,
                      group: true,
                      questionIndex: index,
                    })
                  }
                />
              </div>
            </div>

            {question?.units &&
              (question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
                ? freeTextAllowed
                : true) && (
                <div className={"w-44 mt-2"}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="h-12"
                      value={question?.unit}
                      onChange={(e) =>
                        handleAnswerChange({
                          value: e.target.value,
                          field: question.id,
                          group: true,
                          questionIndex: index,
                          unit: true,
                        })
                      }
                    >
                      {question?.units?.map((unit, idx) => (
                        <MenuItem value={unit} key={idx}>
                          {capitalizeFirstLetter(unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
          </div>
        )
      )}
      {/* {tourGroupQuestions?.map((question, index) =>
        question?.departure || question?.pickup ? (
          <></>
        ) : question.allowedAnswers ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {question.allowedAnswers?.map((answer, idx) => (
                  <MenuItem value={answer} key={idx}>
                    {capitalizeFirstLetter(answer)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : question?.unit === "LOCATION_REFERENCE" ? (
          <div className={Classname.detailInputContainer} key={index}>
            <span className={Classname.detailInputLabel}>
              {question?.label}
              {question?.required === "MANDATORY" ? "*" : ""}
            </span>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="h-12"
                value={question?.answer}
                onChange={(e) =>
                  handleAnswerChange({
                    value: e.target.value,
                    field: question.id,
                    group: true,
                    questionIndex: index,
                  })
                }
              >
                {locations?.map((answer, index1) => (
                  <MenuItem value={answer?.reference} key={index1}>
                    <div className="flex gap-2 items-center">
                      <img
                        src="/IconLocationGreen.svg"
                        alt=""
                        className={"w-5 object-contain"}
                      />
                      <span>{answer?.name}</span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div
            key={index}
            className={
              question?.units
                ? Classname.detailInputContainer1
                : Classname.detailInputContainer
            }
          >
            <div className={Classname.detailInputContainer}>
              <span className={Classname.detailInputLabel}>
                {question?.label}
                {question?.required === "MANDATORY" ? "*" : ""}
              </span>
              <div className={Classname.detailInput}>
                <input
                  type="text"
                  className={Classname.detailInputBar}
                  value={question?.answer}
                  maxLength={question?.maxLength}
                  onChange={(e) =>
                    handleAnswerChange({
                      value: e.target.value,
                      field: question.id,
                      group: true,
                      questionIndex: index,
                    })
                  }
                />
              </div>
            </div>

            {question?.units &&
              (question?.id === "TRANSFER_ARRIVAL_DROP_OFF"
                ? freeTextAllowed
                : true) && (
                <div className={"w-44 mt-2"}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="h-12"
                      value={question?.unit}
                      onChange={(e) =>
                        handleAnswerChange({
                          value: e.target.value,
                          field: question.id,
                          group: true,
                          questionIndex: index,
                          unit: true,
                        })
                      }
                    >
                      {question?.units?.map((unit, idx) => (
                        <MenuItem value={unit} key={idx}>
                          {capitalizeFirstLetter(unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
          </div>
        )
      )} */}
    </div>
  );
};

export default TourOrderUserInfoPage;

const Classname = {
  container:
    "flex w-full min-h-screen relative bg-white flex-col items-center px-3 sm:px-10 font-main",
  content: "flex w-full max-w-7xl flex-col flex-1 ",
  titleContainer: "w-full text-xl font-bold text-gray-400 my-10",
  titleMain: "text-black",

  imagesContainer: "flex flex-wrap w-full gap-6 mb-10",
  mainImageContainer: "relative h-470px flex-1 max-w-xs",
  mainImage: "rounded-xl w-full h-full object-cover",
  mainImageCategory:
    "font-bold text-base absolute top-2 left-2 bg-white/70 py-1 px-2 rounded-md",
  mainImageCTA:
    "font-bold text-xxxs absolute bottom-2 right-2 bg-white/70 py-1 px-2 rounded-md cursor-pointer",
  mainDetailContainer: "flex flex-col flex-1",
  mainDetailName: "text-xl font-bold",
  mainDetailIcon: "",
  mainDetailPoint: "flex items-center gap-2 text-gray-500 mb-2",

  detailContainer: "flex flex-col-reverse lg:flex-row w-full gap-6 mt-6",
  detailContent: "flex flex-col flex-1",
  detailTitleContainer: "flex w-full items-center justify-between mt-2 mb-4",
  detailTitleContainer1: "flex w-full items-center justify-between mt-4 mb-3",
  detailTitle: "text-xl font-bold",
  detailDescription: "mb-2",

  detailPrivacyContainer:
    "w-full bg-blue-500/10 flex items-center gap-2 mb-4 px-6 py-2 rounded-md text-sm",
  detailPrivacyIcon: "",

  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-center gap-2 mb-4 px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",

  detailInputsContainer: "flex justify-between gap-4 flex-wrap",
  detailInputContainer: "flex flex-col flex-1 min-w-[45%]",
  detailInputContainer1:
    "flex flex-row flex-grow flex-1 w-full items-end gap-4",
  detailInput: "flex h-12 rounded-md border border-gray-300 px-4 items-center",
  detailTextArea:
    "flex h-28 rounded-md border border-gray-300 px-4 py-4 items-center",
  detailInputBar: "flex flex-1",
  detailInputLabel: "text-gray-600 mb-2",
  detailPhoneInput: "mt-2",
  detailPayOptionsContainer:
    "flex flex-col lg:flex-row flex-wrap flex-1 gap-4 mt-6 mb-10",
  detailPayOption:
    "flex flex-1 flex-shrink-0 min-w-56 h-24 rounded-lg gap-3 py-2 px-3 cursor-pointer",
  detailPayOptionActive:
    "border-2 border-green-700 bg-green-700/20 text-green-700",
  detailPayOptionInActive: "bg-gray-100",
  detailPayOptionIconContainer: "",
  detailPayOptionIcon: "w-5 h-5 object-contain",
  detailPayOptionIconInActive: "w-5 h-5 rounded-full bg-gray-200",
  detailPayOptionDetail: "flex flex-col gap-1",
  detailPayOptionTitle: "font-bold",
  detailPayOptionDescription: "",
  detailButtonsContainer:
    "flex gap-2 w-full item-center justify-between mt-10 mb-20",
  detailBackButton: "h-12 w-52 rounded-md text-white font-bold bg-gray-400",
  detailContinueButton: "h-12 w-52 rounded-md text-white font-bold bg-primary1",

  detailCTAContainer:
    "flex flex-col flex-1 lg:max-w-md border-2 border-gray-300 p-2 sm:p-4 h-fit rounded-lg",
  detailCTANameContainer:
    "flex flex-col lg:flex-row lg:items-center gap-4 mb-3",
  detailCTAImage: "w-full lg:w-24 h-56 sm:h-96 lg:h-20 rounded-lg object-cover",
  detailCTATitleContainer:
    "flex items-center justify-between text-sm text-gray-600 mt-6",
  detailCTATitleProvider: "text-lg font-bold mt-4 text-black",

  detailCTABenefit:
    "flex bg-blue-600/10 rounded-md w-full py-4 px-4 flex-col mb-2",
  detailCTABenefitTitle: "text-lg font-bold mb-1",
  detailCTABenefitDescription: "flex items-center gap-2 text-sm text-gray-600",
  detailCTABenefitIcon: "",
  detailCTAPriceTitleContainer:
    "flex w-full justify-between text-sm pb-2 border-b-2 border-b-400 mb-4 mt-4",
  detailCTAPriceTitle: "font-bold text-lg",
  detailCTATotalContainer:
    "font-bold text-lg flex w-full justify-between pb-2 pt-3 border-t-2 border-b-400 mb-4 mt-4",
  detailCTADetailContainer: "text-lg flex w-full justify-between text-gray-700",
  detailCTAButton:
    "flex w-full items-center justify-center bg-primary1 rounded-lg h-12 font-bold text-white mt-4",
};
