import React, { useEffect, useState } from "react";
import TicketManagementHeader from "../../../components/TicketManagement/TicketManagementHeader";
import TicketManagementPassengerSelection from "../../../components/TicketManagement/TicketManagementPassengerSelection";
import TicketManagementContactDetails from "../../../components/TicketManagement/TicketManagementContactDetails";
import TicketManagementFlightVoidStepsHeader from "../../../components/TicketManagement/TicketManagementFlightVoidStepsHeader";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmFlightVoidRequestModal } from "../../../redux/reducers/modalSlice";
import TicketManagementLoadingModal from "../../../components/modal/TicketManagementLoadingModal";
import ConfirmFlightVoidRequestModal from "../../../components/modal/ConfirmFlightVoidRequestModal";
import { useNavigate, useParams } from "react-router-dom";
import getBooking from "../../../controllers/booking/getBooking";
import voidTicket from "../../../controllers/booking/postTicketing/void/voidTicket";
import { enqueueSnackbar } from "notistack";

function TicketManagementFlightVoid() {
  const {id} = useParams()
  const [order, setOrder] = useState();
  const [loading,setLoading] = useState({submit: false, fetching: true});
  const [openConfirm,setOpenConfirm] = useState(false);
  const [tickets,setTickets] = useState([])
  const {user} = useSelector(state => state.user.userData);

  const userName = (user?.firstName||'') + ' ' + (user?.middleName||'') + ' ' + (user?.lastName||'');

  const [contact,setContact] = useState({
    name: userName || '',
    email: user?.email || '',
    phone: user?.phone || '',    
  })

  const navigate = useNavigate();
  
  useEffect(() => {
    fetch();
    //eslint-disable-next-line
  }, [id]);

  const fetch = async () => {
    if(!id) return setLoading({...loading,fetching: false});

    setLoading({...loading,fetching: true});
    const res = await getBooking(id);
    setLoading({...loading,fetching: false});
    if (res.return) {
      setOrder(res.data);
    }
  };

  async function handleSubmit() {
    // return console.log(tickets);
    if(!tickets?.length)
      return enqueueSnackbar('No passenger tickets selected! Please select ticket you want to void.',{variant: 'error'})
    setLoading({...loading,submit: true})
    const res = await voidTicket({
      flightBookingId: order?.booking?.flightBooking?.at(0)?._id,
      passengerIds: tickets?.map(obj => obj?.id),
      contactName: contact?.name,
      contactEmail: contact?.email,
      contactPhone: contact?.phone,
    });
    setLoading({...loading,submit: false})
    if(res.return) {
      enqueueSnackbar('Void ticket request has been received.',{variant: 'success'})
      navigate('/order/flight/ticket/detail/'+id+'?page=Void Management')
      // callback && callback()
    } else enqueueSnackbar(res.msg,{variant: 'error'})

  }
  
  return (
    <div className="flex flex-1 flex-col w-full bg-[#F8F8F8] py-4">
      <ConfirmFlightVoidRequestModal {...{open: openConfirm,setOpen: setOpenConfirm}} callback={handleSubmit} />
      <TicketManagementLoadingModal {...{open:loading.submit,setOpen: (val) => setLoading({...loading,submit: val})}} />

      <TicketManagementHeader />
      <TicketManagementFlightVoidStepsHeader step={1} />
      <TicketManagementPassengerSelection order={order} callback={(passengers) => setTickets(passengers)} />

      <TicketManagementContactDetails order={order} data={contact} callback={(obj) => setContact({...contact,...obj})} />
      <TicketActionButtons callback={() => setOpenConfirm(true)} />
    </div>
  );
}

const TicketActionButtons = ({callback}) => {
  const dispatch = useDispatch();
  const handleCancel = () => {};

  const handleSubmit = () => {
    dispatch(setConfirmFlightVoidRequestModal(true));
  };
  return (
    <div className="flex justify-between gap-4 mb-20 mt-10 px-2">
      <button
        onClick={handleCancel}
        className="px-3 py-3 font-bold w-[30%] max-w-[200px] bg-gray-100 rounded-md"
      >
        Cancel
      </button>
      <button
        onClick={() => callback && callback()}
        className="flex flex-1 text-sm font-bold text-white bg-primary1 rounded-md py-3 items-center justify-center max-w-[250px]"
      >
        Submit void request
      </button>
    </div>
  );
};

export default TicketManagementFlightVoid;

const Classname = {
  detailInfoContainer:
    "w-full bg-blue-500/10 flex items-start lg:items-center gap-2 mb-4 px-2 lg:px-6 py-2 rounded-md text-sm mt-6",
  detailInfoIcon: "",
  cardContainer:
    "flex flex-1 flex-col w-full p-2 lg:p-4 bg-white mt-6 rounded-md",
  input: "flex flex-1 py-3 rounded-md px-4 border-2 border-gray-200",
};
